<div id="contact">
    <div class="title-container">
        <h3 class="section-title">Contact Me!</h3>
    </div>
    <nav class="navbar">
        <a class="link" href="mailto:lopez.jason270@gmail.com" target="_blank" rel="noopener noreferrer">
            Email
        </a>
        <a class="link" href="https://www.linkedin.com/in/jason-lopez-74466a16a/" target="_blank" rel="noopener noreferrer">
            LinkedIn
        </a>
        <a class="link" href="https://github.com/jlo270" target="_blank" rel="noopener noreferrer">
            Github
        </a>
    </nav>
</div>