<div id="bio-container">
    <h3>About Me</h3>
    <div class="row">
        <p class="column">
            Education: B.S. Computer Science, Minor in Mathematics - St. Edward's University -
            GPA: 3.73<br><br>
            Interests: Machine Learning, Rock Climbing, Cooking, Reading, Music<br><br>
            Skills: Python, Angular, HTML & CSS, SQL, Java, Javascript
        </p>
        <p class="column">
            Quick Links: <br><br>
            <a class="link" href="https://jalopez.me/JasonLopezResume.pdf" target="_blank" rel="noopener noreferrer">
                Resume
            </a><br><br>
            <a class="link" href="mailto:lopez.jason270@gmail.com" target="_blank" rel="noopener noreferrer">
                Email
            </a><br><br>
            <a class="link" href="https://www.linkedin.com/in/jason-lopez-74466a16a/" target="_blank" rel="noopener noreferrer">
                LinkedIn
            </a><br><br>
            <a class="link" href="https://github.com/jlo270" target="_blank" rel="noopener noreferrer">
                Github
            </a>
        </p>
    </div>
</div>