<!-- Reference -->
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Ubuntu Mono">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Ubuntu">
<link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" rel="stylesheet">
<body>

<div class="container">
    <div id="content-wrapper">
        <app-header></app-header>
        <app-hero></app-hero>
        <app-bio></app-bio>
        <app-projects></app-projects>
        <app-contact></app-contact>
    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>
</body>

<router-outlet></router-outlet>