<div id="projects">
    <h3>Projects & Experience</h3>
    <div class="projects-list">
        <div class="card">
            <div class="container">
              <h4><b>Pokemon AI</b></h4> 
              <p>
                Technologies: Python, PyTorch<br><br>

                Created a modular system for machine learning utilizing the Pokémon game engine as a testing environment for
                pathfinding and strategic decision making learning models. This project is a blend of two of my main interests,
                machine learning and video games, and introduced me to many topics throughout machine learning. 
              </p> 
            </div>
          </div>
          <div class="card">
            <div class="container">
              <h4><b>Software Engineering TaaS</b></h4> 
              <p>
                Technologies: Python, Javascript, MongoDB, HTML, CSS, git, nginx, MapBox API<br><br>

                This project is a POC of a Transport as a Service web application. I worked together on a self-managed team of 
                5 to create the web application, allowing users to request (simulated) vehicle services and track their progress, 
                from the perspectives as a customer and manager. We utilized popular software dev. practices such as PoC diagrams, 
                scrum framework, and User Centered Design.
              </p> <br>
              <a class="link" href="https://github.com/jlo270/software1_taas" target="_blank" rel="noopener noreferrer">
                View Source
            </a>
            </div>
          </div>
          <div class="card">
            <div class="container">
              <h4><b>VMware - ROCS Software Engineer Intern</b></h4> 
              <p>
                Technologies: Angular 9, Typescript, Reactive Programming with RxJS, Clarity HTML/CSS Framework,
                Docker, Node/Express, MySQL, Sequelize ORM, Swagger, Mocha testing framework<br><br>

                This project was a collaboration with with another intern and mentor to develop an internal-external communication platform to
                streamline communication between VMware and its partners. We leveraged various VMware services to create a seamless experience 
                between existing VMware communication applications.
              </p> 
            </div>
          </div>
          <div class="card">
            <div class="container">
              <h4><b>Portfolio Website</b></h4> 
              <p>
                Techonologies: Angular, Typescript, HTML, CSS<br><br>

                This is the website you're current looking at! The site utilized Angular to render multiple
                components.
              </p> <br>
              <a class="link" href="https://github.com/jlo270/jalopez" target="_blank" rel="noopener noreferrer">
                View Source
            </a>
            </div>
          </div>
          <div class="card">
            <div class="container">
              <h4><b>Google CodeU Web App</b></h4> 
              <p>
                Technologies: Java, JavaScript, HTML, Git, CSS, and Google Cloud Platform APIs<br><br>

                I learned and followed industry best practices such as: contributing to open source software using Git and
                GitHub, conducting regular code reviews with/for teammates, extending an existing codebase, designing new
                components and interfaces and leading them to completion. This project was a collaboration with a team of 2 
                peers to design and implement a web application over the course of 12 weeks.I also implemented an administration 
                page containing private site statistics and built a routing engine responsible for handling parsed messages and 
                passing commands to appropriate message bots.
              </p> <br>
              <a class="link" href="https://github.com/jlo270/CodeU-Summer-2018" target="_blank" rel="noopener noreferrer">
                View Source
            </a>
            </div>
          </div>
    </div>
    
</div>