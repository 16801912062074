
<div id="header-container">
    <nav class="navbar">
        <a pageScroll class="link" href="#projects">
            Projects & Experience
        </a>
        <a pageScroll class="link" href="#contact">
            Contact
        </a>
    </nav>
</div>